<template>
    <div class="main" v-if="post">
        <div class="post-img">
            <img :src="`${$store.state.assetsUrl}/${post.imgFeatured}`" class="img-thumb" />
        </div>
        <p class="post-date">{{ new Date(post.published).toLocaleDateString($i18n.locale.toUpperCase() + '-' + $i18n.locale, { year: 'numeric', month: 'long', day: 'numeric' })}}</p>
        <h3>{{post.title}}</h3>
        <p class="post-text" v-html="post.text"></p>
        <div class="post-pdf" v-if="post.pdf">
            {{$t("news.dl")}} 
            <span @click="openPdf(post.pdf)" class="pdf-name">{{post.pdf.split('_')[1]  }}</span>
        </div>
    </div>
    
</template>

<script>
import axios from "axios";


export default {
    name :"BlogItem",
    data() {
        return {
            post : null,
        }
    },
    mounted() {
        let config = {
            validateStatus: () => true,  
        };
        let vm = this;
        axios
            .get(`${this.$store.state.apiUrl}/public/post/${this.$route.params.post}/${this.$i18n.locale}`,  config)
            .then(function (res) {                
                if(res.status != 200) {
                    console.log('Error => ' + res.data.errors)                  
                }else{
                    vm.post = res.data.message;
                }
            });
    },
    methods : {
        openPdf(filename) {
            window.open(`${this.$store.state.apiUrl}/public/pdfs/${filename}`, '_blank');
        } 
    }
}
</script>

<style lang="scss" scoped>
.main {
  width:$blog;
  max-width: $max;
  margin:40px auto;
}

.img-thumb {
    max-width: 100%;
    margin:20px auto;
}

.post-date {
    margin:0;
    color:$mainColor;
    font-size:18px;
    font-weight: 400;
    text-align: center;
}

h3 {
    color:$black;
    font-size:25px;
    font-weight:700;
    text-align: center;
    margin:20px auto 40px;
}

.post-text, .post-pdf, .post-pdf p {
    color:$black;
    text-align: justify;
    font-family: "Comfortaa", cursive;
    font-size:20px;
}

.pdf-name {
    cursor: pointer;
    transition: 0.5s;
}

.pdf-name:hover {
    color:$mainColor;
}

.post-img {
    width:100%;
    display: flex;
    justify-content: center;
    margin-bottom:20px;
}
</style>